<template>
  <div class="wrapper-menu">
    <div class="wrapper__container">
    <div class="container-menu   pb-4">
      <div class="pt-8 px-4">
        <div class="flex justify-between mb-4">
          <jublia-h1 class="flex-grow text-center DINBold text-jublia-bluest "></jublia-h1>
          <icon-close v-if="close==''"
            @click.native="pclick()"
            class="w-6 h-6 cursor-pointer"
          />
        </div>
  
      </div>
      <div class="form"  v-if="step==0">
          <jublia-h1 style="margin-bottom: auto;" class=" text-center DINBold text-jublia-blue" v-if="step==0">{{$t('login.title')}}</jublia-h1>

          <div style="margin-bottom: auto;  width: 273px;">
         <input
            v-model="email" 
            v-bind:placeholder="$t('login.email')"
            type="text"
            
            class="w-full border border-jublia-blue h-12 rounded-lg mt-2 px-4 text-xl"
          />
          <input
            v-model="password" 
            type="password"
             v-bind:placeholder="$t('login.password')"
            class="w-full border border-jublia-blue h-12 rounded-lg mt-2 px-4 text-xl"
          /> 
        
         
        <div
           
           ref="emailloginErr"
           v-if="emailloginErr"
            class="mt-6 border border-red-500 px-4 py-1 mt-2"
            
          >
          {{$t('common.errEmail') }}
          </div>
         
          <span v-if=" i18n.getLocale()=='en'" class="w-4 forget " style="margin-left:64%;" v-on:click="forgetStep()">{{$t('login.reste')}} </span>
          <span v-if=" i18n.getLocale()=='fr'" class="w-4 forget " style="margin-left:46%;" v-on:click="forgetStep()">{{$t('login.reste')}} </span>
              <div @click="robot=!robot" class="flex ml-4 ">
            <span v-if="robot" class="puce selected  border border-jublia-blue border-4" style=" border:3px solde #006699 !important;" >✓</span>
            <span v-else class="puce  border border-jublia-blue border-4"  style=" border:3px solde #006699 !important;"/>
            <jublia-p class="cursor-pointer  text-black textline ml-4" style="color: #000000a3;font-size: 1rem;">
              {{ $t('login.robot') }}
            </jublia-p>
          </div>
           <div
           
           ref="errorobot"
           v-if="errorobot"
            class="mt-6 border border-red-500 px-4 py-1 mt-2"
            
          >
          {{$t('login.errorobot') }}
          </div>
           
          <div
           ref="errorlogin"
           v-if="errorlogin"
            class="mt-6 border border-red-500 px-4 py-1 mt-2"
          >
           {{$t('login.error')}}
          </div>
          <div
           ref="errorUnauthorizednEmail"
           v-if="errorUnauthorizednEmail"
            class="mt-6 border border-red-500 px-4 py-1 mt-2"
          >
           {{$t('login.errorExist')}}
          </div>
          <div
           ref="errorUnauthorizednPass"
           v-if="errorUnauthorizednPass"
            class="mt-6 border border-red-500 px-4 py-1 mt-2"
          >
           {{$t('login.erroroPassword')}}
          </div>
          </div>
         
         
          <jublia-button
          :width="true"
          :disable="email==''||password=='' "
           :bdgray="email==''||password==''"
             v-on:click="login()"
            class="relative mt-2 text-xl DINBold font-bold border-jublia-blue"
            style="text-transform: uppercase;"
          >
         <p class="text-xl DINBold font-bold">{{ $t('login.login') }}</p>
          </jublia-button>
        </div>
        <!--get email-->
        <div class="form"  v-if="step==1">
          <jublia-h1 style="margin-bottom: auto" class=" text-center DINBold text-jublia-blue">{{$t('reset.title')}}</jublia-h1>
          <div style="margin-bottom: auto;  width: 282px;">
        <div style="margin-bottom: auto; text-align:center">
        
          <jublia-p class="text">{{$t('reset.descript')}}</jublia-p>
          <jublia-p class="text">{{$t('reset.descripta')}}</jublia-p>
          
          </div>
          <input
            v-model="emailf" 
            type="email"
             v-bind:placeholder="$t('login.email')"
            class="w-full border border-jublia-blue h-12 rounded-lg mt-2 px-4 text-xl"
          /> 
          
           <div
           
           ref="errorforget"
           v-if="errorforget"
            class="mt-6 border border-red-500 px-4 py-1 mt-2"
            
          >
         
          {{$t('reset.error') }}
          
          </div>
      <div
           
           ref="emailerr"
           v-if="emailerr"
            class="mt-6 border border-red-500 px-4 py-1 mt-2"
            
          >
          {{$t('common.errEmail') }}
          </div>
          </div>

          <jublia-button
          :width="true"
           :disable="(emailf=='')"
           :bdgray="(emailf=='')"
             v-on:click="forget()"
            class="relative mt-2 border-jublia-blue"
           style="text-transform: uppercase;"
          >
         {{ $t('form-din.valider') }}
          </jublia-button>
        </div>
        <!--msg tnx reset-->
         <div class="form"  v-if="step==2">
          <jublia-h1 style="margin-bottom: auto" class=" text-center DINBold text-jublia-blue">{{$t('reset.title')}}</jublia-h1>
          <div style="margin-bottom: auto; text-align:center">
        
         <jublia-p class="text">{{$t('reset.texta')}}</jublia-p>
          <jublia-p class="text">{{$t('reset.textb')}}</jublia-p>
          <jublia-p class="text">{{$t('reset.textc')}}</jublia-p>
          <jublia-p class="text">{{$t('reset.texte')}}</jublia-p>
          
          </div>
          
          <jublia-button
          :width="true"
             v-on:click="pclick()"
            class="relative mt-2 border-jublia-blue"
            style="text-transform: uppercase;"
 
          >
             {{ $t('user-settings.back') }}      
          </jublia-button>
        </div>
 <div class="form"  v-if="step==10">
          <jublia-h1 style="margin-bottom: auto" class=" text-center DINBold text-jublia-blue">{{$t('recover.password')}}</jublia-h1>
            <div style="margin-bottom: auto;  width: 227px;">
         <div style="margin-bottom: auto; text-align:center">
        
          <input
            v-model="password" 
            type="password"
             v-bind:placeholder="$t('recover.password')"
            class="w-full border border-jublia-blue h-12 rounded-lg mt-2 px-4 text-xl"
          /> 
          
          <input
            v-model="password2" 
            type="password"
            @input="verify($event)"
             v-bind:placeholder="$t('recover.newPassword')"
            class="w-full border border-jublia-blue h-12 rounded-lg mt-2 px-4 text-xl"
          /> 
          <div
           ref="  errorcover"
           v-if="  errorcover"
            class="mt-6 border border-red-500 px-4 py-1 mt-2"
          >
                    {{$t('reset.error')}}

          </div>
          </div>
          </div>
          
          <jublia-button
           :disable="password2!=password || password2=='' || password==''"
           :bdgray="password2!=password || password2=='' || password==''"
           :width="true"
           style="text-transform: uppercase;"
             v-on:click="getPassword()"
            class="relative mt-2 border-jublia-blue "
            
          >
          {{ $t('form-din.valider') }}
          </jublia-button>
        </div>
          <div class="form"  v-if="step==12">

             <slot name="header"  class="text">
                </slot>

          <div style="margin-bottom: auto;  width: 282px;">
        <div style="margin-bottom: auto; text-align:center">
        
          
          
          </div>
         
                <slot name="body"  class="text">
                </slot>

               
          
           <div
           
           ref="errorforget"
           v-if="errorforget"
            class="mt-6 border border-red-500 px-4 py-1 mt-2"
            
          >
         
          {{$t('reset.error') }}
          
          </div>
      <div
           
           ref="emailerr"
           v-if="emailerr"
            class="mt-6 border border-red-500 px-4 py-1 mt-2"
            
          >
          {{$t('common.errEmail') }}
          </div>
          </div>
           <slot name="footer"  class="text">
         
          </slot>
        </div>
     </div>
    </div>
  </div>
</template>

<script>
import IconClose from '@/components/icons/close'
import JubliaH1 from '@/components/text/jublia-h1'
import JubliaP from '@/components/text/jublia-p'

import JubliaButton from '@/components/button/jublia-button'
import { useI18n } from "vue3-i18n";
import { ref ,onMounted} from "vue";
import {useRouter,useRoute  } from "vue-router";
import { useStore} from 'vuex'
import axios from 'axios';

export default {
  components: {
     'jublia-button':JubliaButton,
   'close': IconClose,
    'jublia-h1':JubliaH1,
    'jublia-p':JubliaP,
    'icon-close':IconClose
  },
   props: {
  token: {
      type: String,
      default:''
    },
    state: {
      type: String,
      default:''
    },
    close: {
      type: String,
      default:''
    },
  },
  setup(props, context) {
    const email=ref("");
    const emailf=ref("");
    const step=ref(0);
    let errorlogin=ref(null)
    let emailerr=ref(false)
    let robot=ref(false)
    let errorobot=ref(false)
    let emailloginErr=ref("")
    let errorforget=ref(null)
    let errorcover=ref(null)
    let errorUnauthorizednEmail=ref(null)
     let errorUnauthorizednPass=ref(null)
    const password=ref("")
    const password2=ref("")
     const store= useStore()
      const router  = useRouter()  
        const route = useRoute()
    const i18n = useI18n();

     onMounted(function() {
       if(props.token)step.value=10;
        if(props.state=='op')step.value=12;
        
    }); 
   
    const setLocale = () => {
      i18n.setLocale(i18n.getLocale() === 'fr' ? 'en' : 'fr');

    };
    
  function validName(email) {
  if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(email)){
    return false;
  }
  return true;
}
     const verify = (e) => {
      if(e.target.value!==password.value) errorcover.value="Password no"
      if(e.target.value===password.value)   errorcover.value=null
     
    };
    const emailfn = (e) => {
     (emailf.value==='')?  emailloginErr.value='' : (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(emailf.value)) ?
       emailerr.value='' : emailerr.value='has-error';
       (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value)&&emailf.length > 0) ?
       emailloginErr.value='' : emailloginErr.value='has-error';
    
    };
    const forgetStep = () => {
      step.value=1
    };
    const pclick = ()=> {
 context.emit("update:is-open", false);
 
 if(props.token){
   router.push({name: 'din'});
  context.emit("close");}
   if(props.state=='op'){
    context.emit("close");}
}
 
  const login= ()=> {
//errorlogin._value=1
errorUnauthorizednEmail.value=null
errorUnauthorizednPass.value =null
errorlogin.value=null
if(!robot.value){errorobot.value=true}
else{
errorobot.value=false
 let cond=validName(email.value)
    emailloginErr.value=cond
    if(!cond){
      store.dispatch('login/login', { email:email._value, password:password._value , lang: i18n.getLocale()}).then(()=>{
            if(store.getters["login/msg"]=="Error: Request failed with status code 401"){
                   store.dispatch("login/initErr")
                   throw Error('Error unauthentication')

          }
          if(store.getters["login/msg"]=="Error: Request failed with status code 406"){
                   store.dispatch("login/initErr")
                   throw Error('Error password')

          }
         if(store.getters['login/isAuthenticated']===false){
                   throw Error('Error authentication')

          }
          if(store.getters['login/isAuthenticated']===true){
          router.push({name: 'menu'})
          }
        }) .catch(e => {
            if(e.message=="Error unauthentication")
          errorUnauthorizednEmail.value = e.message;
           if(e.message=="Error password")
          errorUnauthorizednPass.value = e.message;
           if(e.message=="Error authentication")
          errorlogin.value=e.message
      })
    }
    }
   
 };
const forget = ()=> {
   
   
    let cond=validName(emailf.value)
    emailerr.value=cond
    if(!cond){
    
   
    axios.post(`user/reset_password/${emailf._value}`,{lang: i18n.getLocale()})
      .then(res => {
       step.value=2
       
      
      })
      .catch(e => {
          errorforget.value=e.message
        
        console.log( errorforget.value)
      })
   }
  };
 

const getPassword = ()=> {
   
     axios.post(`user/recover_password/${route.params.token}`, {
        password:password._value
        })
      .then(res => {
         errorcover.value=''
        pclick()
        })
      .catch(e => {
         
         errorcover.value=e.message

        console.log(e)
      })
      
   
  };
    return {
      setLocale,
      getPassword,
      verify,
       errorcover,
      errorlogin,
      errorforget,
      forget,
      i18n,
      login,
      emailf,
      email,
      emailfn,
      pclick,
      forgetStep,
      step,    
      emailerr,
      emailloginErr,
      password2,
      robot,
      errorUnauthorizednPass,
      errorUnauthorizednEmail,
      errorobot,
      password
    };
  },

  methods: {
    switchLng(lang) {
      this.$emit('change', lang)
    },
    logout(){
      this.$store.dispatch('login/AUTH_LOGOUT')
    },
    loginv(){
      this.$store.dispatch('login/AUTH_LOGIN')
    },
    getLocale() {
      return this.$i18n.getLocale()
    },
  }
}
</script>

<style scoped lang="postcss">
.wrapper-menu {
  
     @apply w-full fixed top-0  left-0;
    z-index: 99;
  min-height: 100vh;
  @media all and (min-width: 768px) {
    @apply flex items-center justify-center flex-col bg-jublia-gray ;
  }
}
.text{
  text-align: center !important;
  font-weight: 500;
}
input,::placeholder {
  color: #000000a3;
font-size: 1rem;
}
.forget {
   /* @apply pl-2.5;*/
  /*padding-left:80%;*/
  cursor: pointer;
  
   font-size:12px;
    height:auto
@screen md{
     padding-left:30% !important;
   /* margin: 0 auto;*/
  }
}

.form {
    @apply  w-full h-full flex flex-col justify-center items-center px-4  ;
        min-height: 23rem;

}
.container-menu {
  @apply h-full w-11/12 flex flex-col justify-start z-20  border-jublia-purple border-4;
   @apply rounded-2xl;
   margin: auto !important;
   min-width: 21rem;
   min-height:27rem;
  @media all and (min-width: 768px) {
    max-width: 900px;
   /* margin: 0 auto;*/
  }
}

.logo {
  @apply absolute bottom-0 right-0;
  margin-bottom: -7px;
}

.puce {
  @apply block  text-lg  flex justify-center items-center cursor-pointer rounded-sm border border-jublia-blue;
  width: 20px;

  height: 20px;
  margin-right: 5px;
  padding-top: 4px;
}
.selected {
  @apply bg-jublia-white;
}
</style>

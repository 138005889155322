<template>
<div class="contain">
   <slot />

    <div class=" flex;
  items-center justify-center pr-4"  >
 
 <input  
  type="checkbox"  
 
  v-model="isEnabled"  @click="toggle" :id="ids" /><label :for="ids">Toggle</label>
    </div>
  
    
</div>
</template>

<script>
 import {mapMutations, mapState, mapActions} from 'vuex'

export default {


  computed: {
      style () {
        return 'background-color: ' + this.color ;
      }
    },
   

  props: {
    isEnabled: Boolean,
     ids: String,
    color: {
      type: String,
      required: false,
      default: "#4D4D4D"
    }
  },
  methods: {
    toggle: function() {
     
     
    this.$emit("changet", !this.isEnabled); 
    }
  }
}
</script>

<style scoped >
.contain {
  @apply flex;
  @apply items-center justify-between;
  @apply w-full;

/*  @apply py-2 ;*/
  @apply min-w-full;
 /* @apply rounded-full;*/
  @apply rounded-2xl;
  @apply text-jublia-white;
 
 @apply font-black;
 @apply text-lg;
 font-size: 1.25rem;
}

input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

label {
	cursor: pointer;
	text-indent: -9999px;
	width: 77px;
height: 37px;
	background: grey;
	display: block;
	border-radius: 100px;
    margin-bottom: 0.7rem;
	position: relative;
}

label:after {
	content: '';
	position: absolute;
   top: 4px;
    left: 5px;
    width: 30px;
   height: 30px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

input:checked + label {
	background: #bada55;
}

input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

label:active:after {
	width: 130px;
}

 

</style>

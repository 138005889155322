<template>
  <div class="wrapper ">
     <div class="wrapper__container">
      <jublia-header />
      
    <div class="container container-transparent">
     
       <jublia-h1 class="DINBold text-jublia-bluest mb-2 uppercase">
          {{ $t("sms-remaind.title") }}
        </jublia-h1>

        <div class="mt-10 w-full">
          <div class="relative flex flex-col  w-full mt-2">
            <div style="margin: auto; margin-bottom: 0.3rem; width: 74%"
            class="relative flex flex-col items-center justify-center w-full mt-2"
            >
               <img
                src="@/assets/icons/SMS.svg"
                alt="next button"
                width="198"
                height="18"
                style="margin-bottom: 1rem;"
              />
               <div
                  style="
                    margin-bottom: 3.5rem;
                    text-align: center;
                    height: auto;
                    padding: 11px;
                   
                  "
                   v-on:click="popclk('op')"
                  class="
                    w-full
                    border border-jublia-blue
                    h-12
                    rounded-lg
                    mt-2
                    px-4
                    text-xl text-jublia-blue
                    cursor-pointer
                  "
                >
                  {{ $t("sms-remaind.text") }}
                </div>
            </div>
            <jublia-toggle class="mx-4" :isEnabled="toggle1" v-bind:ids="'refill'" @changet="updateRefill('refill')" > 
               <jublia-p 
               style="width: 59%  !important;font-size:1.3rem"
               class=" text-jublia-bluest text-base mt-6"    > 
               {{ $t("sms-remaind.btn") }}</jublia-p>
               </jublia-toggle>
               <jublia-toggle class="mx-4 " :isEnabled="toggle2" v-bind:ids="'remaind'"  @changet="updateRemaind('remaind')" > 
               <jublia-p 
                style="width: 65%  !important;font-size:1.3rem"
               class=" text-jublia-bluest  mt-6 "    > 
               {{ $t("sms-remaind.btnr") }}</jublia-p>
               </jublia-toggle>
                <div
          @click="go(`/error-form-treatment`)"
          v-html="$t('email-remaind.calc')"
          style="text-align: center !important;"
          class="block text-base underline cursor-pointer  text-center mt-14 text text2 lg:w-1/2 md:w-1/2 lg:mx-auto md:mx-auto"
        />
                <div
          v-if="(submited)"
          class="border border-red-500 px-4 py-2 mt-4 text-red-500 text-base text"
        >
        
         <p class="text">  {{$t('sms-remaind.error') }}</p>
        </div>
        <pop v-if="popup"  v-model:is-open="popup"  @close="close()"  :state="step">
         <template  v-slot:header>
        <jublia-h1  style="margin-bottom: auto" class=" text-center DINBold text-jublia-blue">{{$t('user-settings.titleUpdate')}}</jublia-h1>
        </template>

        <template  v-slot:body>
          <jublia-p class="text" style="text-align: center !important;">{{$t('user-settings.pUpdate1')}}</jublia-p>
          <jublia-p class="text  " style="text-align: center !important;margin-bottom: 0.6rem;">{{$t('user-settings.pUpdate2')}}</jublia-p>
          <input
            v-model="v$.phoneInput.$model" 
            type="text"
            placeholder="xxx-xxx-xxxx"
             v-mask="['###-###-####']"
           class="w-full border border-jublia-blue h-12 rounded-lg mt-2 px-4 text-xl"
           style="margin-bottom: 2rem;"
          />
           <div
          v-if="(submited && error)"
          class="border border-red-500 px-4 py-2 mt-4 text-red-500 text-base text"
        >
         <p class="text"> {{ $t('treatment.reminder.errorPhone') }}</p>
        </div>
         <div
          v-if="(submited)"
          class="border border-red-500 px-4 py-2 mt-4 text-red-500 text-base text"
        >
         <p class="text">  {{$t('reset.error') }}</p>
        </div>
        
          </template>
          <template  v-slot:footer>
           <div class="mt-14 w-full flex  justify-around items-center mb-4">
             <jublia-button
              
            :bdelete="true"
            @click="close();"
            class="relative mt-2 border-jublia-blue  mr-8"
           style="text-transform: uppercase;"
          >
           <div  style="line-height: 30px !important; " class="mt-2 mb-2">
         {{ $t('user-settings.back') }}
         </div>
          </jublia-button>
           <jublia-button
              
            :bdelete="true"
           @click="updatePhone();" 
            class="relative mt-2 border-jublia-blue"
           style="text-transform: uppercase;"
          >
           <div  style="line-height: 30px !important; " class="mt-2 mb-2">
         {{ $t('form-din.valider') }}
           </div>
          </jublia-button>
          </div>
      </template>
      </pop>
            
            <jublia-button
          :width="true"
           @click="go(`/menu`)"
            class="relative m-6 mx-auto border-jublia-blue font"
           style="text-transform: uppercase; font-size: 1.8rem;"
          >
          <div  style="line-height: 30px !important; " class="mt-2 mb-1">
         {{ $t('user-settings.back') }}
         </div>
          </jublia-button>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import JubliaP from "@/components/text/jublia-p";
import JubliaToggle from "@/components/toggle/toggle";
import JubliaSwitcher from "@/components/toggle/switcher";
import axios from "axios";
import JubliaButton from "@/components/button/jublia-button";
import JubliaH1 from "@/components/text/jublia-h1";
import JubliaH3 from "@/components/text/jublia-h3";

import { mask } from 'vue-the-mask'
import {mapState, mapMutations ,mapActions,mapGetters,useStore } from "vuex";
import {maxLength, minLength} from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import JubliaHeader from "@/components/header/jublia-header";
import pop from '@/components/back-pop/back-pop'
export default {
  name: "login",

  components: {
    //JubliaButton,
    pop,
    "jublia-button": JubliaButton,
    "jublia-header": JubliaHeader,
   "jublia-h3": JubliaH3,
    "jublia-h1": JubliaH1,
    "jublia-p": JubliaP,
    "jublia-toggle":JubliaToggle,
     "jublia-switcher":JubliaSwitcher
  },
 data: () => ({
    error: false,
    phoneInput: null,
    step:null,
    user: {},
    popup:false,
    treatmentProgress: true,
    submited: false,
    toggle1:false,
    toggle2:false,
    popup:false,
    v$:null
  
  
  }),
  directives: {
    mask
  },
    computed: {
    ...mapState({
      url: (state) => state.login.url,
      profil: (state) => state.login.userProfile,
      a: (state) => state.login.token,
      phone: (state) => state.reminder.phone,
      state: (state) => state.login.status,
      reminder: (state) => state.reminder.subscribed
    }),
  ...mapGetters({
     doneCount: 'login/userProfile',
     })
  
  },
  created: function () {
   
   if(!!sessionStorage.getItem('user')){
      this.user= JSON.parse(sessionStorage.getItem('user'))||{}
   }
   this.toggle1=this.user.smsRefill
   this.toggle2=this.user.smsRemaind
     this.v$ =useVuelidate();
  },
  methods: {
    ...mapActions({
      update: 'login/updateSms',
      delete: 'treatment/smallToesInfected'
    }),
    update(name){
      let smsActive=null
      if(name=='refill')smsActive=this.toggle1;
       if(name=='remaind')smsActive=this.toggle2;
        const datas = {
          email:this.user.email||'',
           smsRefill: this.toggle1,
           smsRemaind: this.toggle2,
           smsActive, 
           phone: this.user.phone,
           name
        }
     this.$store.dispatch('login/updateSms',datas).then(()=>{
           if(this.state!='error'){
            
         }
         if(this.state=='error'){
          this.submited = true
          return null
         }
           }).catch((e)=>{
          this.submited = true
        })
    },
   popclk(a){
      this.step=a
      this.popup=true;

},
     go(a){
  
     this.$router.push(a)
    },
  updateRefill(name){
   
   this.toggle1=!this.toggle1
    this.update(name)
   
 },
   updateRemaind(name){
   this.toggle2=!this.toggle2
        this.update(name)
 },
 updatePhone() {
     
      this.error = false
    
       if (this.v$.phoneInput.$error) {
                   this.error = true
                   this.submited = true
         return null}
      
        if (this.phoneInput === null ||this.phoneInput === '') {
           this.submited = true
          this.error = true
          return null
        } else {
                     let ph=this.phoneInput
           
           this.$store.dispatch('login/editUser',{ email:this.user.email,phone:this.phoneInput}).then(()=>{
          if(this.state!='error'){

          this.user.phone=this.phoneInput
           this.popup=false;
            this.phoneInput=''
         }

          if(this.state=='error'){
          this.submited = true
          return null
         }
           })
        
        }
    },
    close(){
       this.error = false
        this.submited = false
         this.popup=false;
          this.popup2=false;
          this.phoneInput=''
},
    ...mapMutations({
      setSubscribed: 'reminder/setSubscribed',
      setPhone: 'reminder/setPhone',
      setUrl: 'login/setUrl'
    })
  },
   validations () {
  return {  phoneInput: {
      minLength: minLength(12),
      maxLength: maxLength(12)
    },
  }
   }
}
</script>
<style scoped>
.container-ins {
  max-width: 870px;
  margin: 0 auto;
  
}
input,.email, input[placeholder] {
    text-align: center;
    @apply text-gray-700;
}
.container-content {
  @apply w-full;
  @apply m-auto;
  @apply pb-8;
  @apply px-8;
  max-width: 900px;

  @media all and (min-width: 767px) {
    max-width: 500px;
  }
}
</style>
